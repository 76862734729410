import * as React from "react";
import {
  Flex,
  Box,
  Text,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  useToast,
  HStack,
  VStack,
  Heading,
  List,
  ListItem,
  Switch,
  Button,
  FormLabel,
  Input,
  FormErrorMessage,
  FormControl,
  Select,
  Textarea,
  Spacer,
} from "@chakra-ui/react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Answer, Question } from "../../types/QuestionnaireTypes";
import { addQuestion, getQuestion, updateQuestion } from "../../api/QuestionService";
import { AddIcon } from "@chakra-ui/icons";

const createSchema = yup.object().shape({
  key: yup
    .string()
    .min(1, "Question key must be at least 1 character")
    .required("Question key is required"),
  text: yup
    .string()
    .max(100, "Question text must be at most 100 characters")
    .required("Question text is required"),
  active: yup.boolean().required(),
  required: yup.boolean().required(),
  type: yup.string().required("Question type is required"),
  answers: yup.lazy((value, options) => {
    const { parent } = options;
    const qType = parent?.type;
    if (["TEXT_INPUT", "MULTILINE_TEXT", "HIDDEN"].includes(qType)) {
      return yup.array().notRequired();
    } else if (qType === "SINGLE_CHOICE_NUMERIC") {
      return yup
        .array()
        .of(
          yup.object().shape({
            key: yup
              .string()
              .min(1, "Answer key must be at least 1 character")
              .required("Answer key is required"),
            text: yup
              .string()
              .matches(/^\d+$/, "Answer field must be numeric")
              .required("Answer text is required"),
            active: yup.boolean().required(),
          })
        )
        .min(1, "At least one answer is required")
        .required();
    } else {
      return yup
        .array()
        .of(
          yup.object().shape({
            key: yup
              .string()
              .min(1, "Answer key must be at least 1 character")
              .required("Answer key is required"),
            text: yup
              .string()
              .max(100, "Answer text must be at most 100 characters")
              .required("Answer text is required"),
            active: yup.boolean().required(),
          })
        )
        .min(1, "At least one answer is required")
        .required();
    }
  }),
});

const editSchema = yup.object().shape({
  key: yup.string().min(1),
  text: yup.string().max(100),
  active: yup.boolean(),
  required: yup.boolean(),
  type: yup.string(),
  answers: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      text: yup.string().max(100),
      active: yup.boolean(),
    })
  ),
});

export function QuestionDetails() {
  const toast = useToast();
  let { questionId } = useParams<{ questionId: string }>();
  let match = useRouteMatch();
  let history = useHistory();
  const [type, setType] = useState("");
  
  const schema = questionId ? editSchema : createSchema;

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<Question>({
    mode: "onBlur",
    defaultValues: {
      key: "",
      text: "",
      active: true,
      required: true,
      type: "",
      answers: [
        { key: "", text: "", active: true },
        { key: "", text: "", active: true },
        { key: "", text: "", active: true },
      ],
    },
    resolver: yupResolver(schema),
  });

  const [keyState, setKeyState] = useState("");
  const [textState, setTextState] = useState("");
  const [status, setStatus] = useState(true);
  const [requiredState, setRequiredState] = useState(true);
  const [answers, setAnswers] = useState<Answer[]>([
    { key: "", text: "", active: true },
    { key: "", text: "", active: true },
    { key: "", text: "", active: true },
  ]);

  const editActiveAnswer = (i: number) => {
    const temp = [...answers];
    temp[i].active = !temp[i].active;
    setAnswers(temp);
    setValue(`answers.${i}.active`, temp[i].active);
  };

  const editKeyAnswer = (i: number, value: string) => {
    const temp = [...answers];
    temp[i].key = value;
    setAnswers(temp);
    setValue(`answers.${i}.key`, value);
  };

  const editTextAnswer = (i: number, value: string) => {
    const temp = [...answers];
    temp[i].text = value;
    setAnswers(temp);
    setValue(`answers.${i}.text`, value);
  };

  const addNewAnswer = () => {
    const temp = [...answers];
    const answer: Answer = { active: true, key: "", text: "" };
    temp.push(answer);
    setAnswers(temp);
    setValue("answers", temp);
  };

  const saveAction = async () => {
    const isFormValid = await (async () => {
      return await Promise.resolve(
        await (handleSubmit(() => true)(), true)
      );
    })();

    if (isFormValid) {
      const formValues = getValues();
      const question: Question = {
        key: formValues.key,
        type: formValues.type,
        text: formValues.text,
        active: formValues.active,
        required: formValues.required,
        answers: ["TEXT_INPUT", "MULTILINE_TEXT", "HIDDEN"].includes(formValues.type)
          ? []
          : answers,
      };

      if (!questionId) {
        createQuestion(question);
      } else {
        modifyQuestion(question);
      }
    } else {
      toast({
        title: "Validation Error",
        description: "Please correct the errors before saving.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const createQuestion = (question: Question) => {
    addQuestion(question)
      .then((response: AxiosResponse) => {
        if ([200, 201].includes(response.status)) {
          toast({
            title: "Question",
            position: "top",
            description: "Question and Answers added successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          history.push("/questionnaires");
        } else {
          return Promise.reject(response.data.apierror.message);
        }
      })
      .catch((error: any) => {
        toast({
          title: "Failed to add Question",
          position: "bottom",
          description: JSON.stringify(
            error?.response?.data?.apierror
              ? error.response.data.apierror.debugMessage
              : error?.message
          ),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const modifyQuestion = (question: Question) => {
    updateQuestion(question)
      .then((response: AxiosResponse) => {
        if ([200, 201].includes(response.status)) {
          toast({
            title: "Question",
            position: "top",
            description: "Question and Answers updated successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          history.push("/questionnaires");
        } else {
          return Promise.reject(response.data.apierror.message);
        }
      })
      .catch((error: any) => {
        toast({
          title: "Failed to update Question",
          position: "bottom",
          description: JSON.stringify(
            error?.response?.data?.apierror
              ? error.response.data.apierror.debugMessage
              : error?.message
          ),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (questionId) {
      const params = { key: questionId };
      getQuestion(params)
        .then((response: AxiosResponse) => {
          if ([200, 201].includes(response.status)) {
            const question: Question = response.data;
            setKeyState(question.key as string);
            setValue("key", question.key);
            setType(question.type as string);
            setValue("type", question.type);
            setTextState(question.text as string);
            setValue("text", question.text);
            setStatus(question.active as boolean);
            setValue("active", question.active);
            setRequiredState(question.required as boolean);
            setValue("required", question.required);
            if (!["TEXT_INPUT", "MULTILINE_TEXT", "HIDDEN"].includes(question.type as string)) {
              setAnswers(question.answers as Answer[]);
              setValue("answers", question.answers);
            }
          } else {
            return Promise.reject(response.data.apierror.message);
          }
        })
        .catch((error: any) => {
          toast({
            title: "Failed to get Question from backend",
            position: "bottom",
            description: JSON.stringify(
              error?.response?.data?.apierror
                ? error.response.data.apierror.debugMessage
                : error?.message
            ),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          history.push("/questionnaires");
        });
    }
  }, [questionId, history, toast, setValue]);

  useEffect(() => {
    setValue("type", type);
  }, [type, setValue]);

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
          <BreadcrumbItem color="gostation.100">
            <BreadcrumbLink as={Link} to="/questionnaires">
              <Text>Questionnaires</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem color="gostation.500" isCurrentPage>
            <BreadcrumbLink as={Link} to={match.url}>
              {questionId ? <Text>Edit Question</Text> : <Text>New Question</Text>}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <form onSubmit={handleSubmit(saveAction)}>
        <Flex w="96%" minH="500px" ml={6} mr={6} bg="white">
          <HStack>
            <VStack minW="550px" minH="500px" pl={4} pr={4} alignItems="left">
              <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">
                Question Definition
              </Heading>
              <HStack>
                {/* Question Status */}
                <FormControl id="status" w="160px" p="1">
                  <FormLabel fontSize="xs" color="gray">
                    Question Status
                  </FormLabel>
                  <Controller
                    control={control}
                    name="active"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        colorScheme="primary"
                        size="md"
                        isChecked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setStatus(e.target.checked);
                        }}
                      />
                    )}
                  />
                </FormControl>
                {/* Required */}
                <FormControl id="required" w="160px" p="1">
                  <FormLabel fontSize="xs" color="gray">
                    Required
                  </FormLabel>
                  <Controller
                    control={control}
                    name="required"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        colorScheme="primary"
                        size="md"
                        isChecked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setRequiredState(e.target.checked);
                        }}
                      />
                    )}
                  />
                </FormControl>
              </HStack>
              {/* Question Type */}
              <FormControl id="type" w="315px" p="1" isInvalid={!!errors.type?.message}>
                <FormLabel fontSize="xs" color="gray">
                  Select Question Type
                </FormLabel>
                <Select
                  placeholder="Please Select"
                  value={type}
                  {...register("type")}
                  onChange={(e) => {
                    setType(e.target.value);
                    setValue("type", e.target.value);
                  }}
                >
                  <option value="TEXT_INPUT">Free Text</option>
                  <option value="SINGLE_CHOICE">Single Choice</option>
                  <option value="MULTIPLE_CHOICE">Multiple Choice</option>
                  <option value="MULTILINE_TEXT">Multiline Text</option>
                  <option value="SINGLE_CHOICE_NUMERIC">Numeric Single Choice</option>
                  <option value="HIDDEN">Hidden</option>
                  <option value="DETAILED_MULTIPLE_CHOICE">Detailed Multiple Choice</option>
                </Select>
                <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
              </FormControl>
              {/* Question Key */}
              <FormControl
                id="key"
                w="315px"
                p="1"
                isInvalid={!!errors?.key?.message}
              >
                <FormLabel fontSize="xs" color="gray">
                  Question Key
                </FormLabel>
                <Input
                  {...register("key")}
                  isDisabled={!!questionId}
                  placeholder="Type unique key"
                  value={keyState}
                  size="md"
                  onChange={(e) => {
                    setKeyState(e.target.value);
                    setValue("key", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors?.key?.message}</FormErrorMessage>
              </FormControl>
              {/* Question Text */}
              <FormControl
                id="text"
                w="500px"
                p="1"
                isInvalid={!!errors?.text?.message}
              >
                <FormLabel fontSize="xs" color="gray">
                  Question Text
                </FormLabel>
                <Textarea
                  {...register("text")}
                  placeholder="Type the question here"
                  value={textState}
                  size="md"
                  onChange={(e) => {
                    setTextState(e.target.value);
                    setValue("text", e.target.value);
                  }}
                />
                <FormErrorMessage>{errors?.text?.message}</FormErrorMessage>
              </FormControl>
              <Spacer />
              <HStack spacing={6} p={4}>
                <Button as={Link} to="/questionnaires" colorScheme="gostation" variant="outline" size="md">
                  CANCEL
                </Button>
                <Button onClick={saveAction} mr="auto" colorScheme="gostation" variant="solid" size="md">
                  PUBLISH
                </Button>
              </HStack>
            </VStack>
            {(type === "SINGLE_CHOICE" ||
              type === "MULTIPLE_CHOICE" ||
              type === "DETAILED_MULTIPLE_CHOICE" ||
              type === "SINGLE_CHOICE_NUMERIC") && (
              <VStack minW="600px" minH="500px" pl={4} pr={4} alignItems="left">
                <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">
                  Answers
                </Heading>
                <List spacing={3} variant="simple" size="sm">
                  {answers.map((answer: Answer, i: number) => (
                    <ListItem p={1} key={i}>
                      <HStack>
                        {/* Answer Status */}
                        <FormControl id={`answers[${i}].active`} minW="90px">
                          <FormLabel fontSize="xs" color="gray">
                            Answer Status
                          </FormLabel>
                          <Controller
                            control={control}
                            name={`answers.${i}.active`}
                            defaultValue={answer.active}
                            render={({ field: { onChange, value } }) => (
                              <Switch
                                colorScheme="primary"
                                size="md"
                                isChecked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  editActiveAnswer(i);
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        {/* Answer Key */}
                        <FormControl
                          id={`answers[${i}].key`}
                          minW="90px"
                          isInvalid={!!errors?.answers?.[i]?.key?.message}
                        >
                          <FormLabel fontSize="xs" color="gray">
                            Answer Key
                          </FormLabel>
                          <Input
                            {...register(`answers.${i}.key` as const)}
                            placeholder="Type key"
                            value={answer.key}
                            size="xs"
                            onChange={(e) => editKeyAnswer(i, e.target.value)}
                          />
                          <FormErrorMessage>{errors?.answers?.[i]?.key?.message}</FormErrorMessage>
                        </FormControl>
                        {/* Answer Text */}
                        <FormControl
                          id={`answers[${i}].text`}
                          minW="320px"
                          isInvalid={!!errors?.answers?.[i]?.text?.message}
                        >
                          <FormLabel fontSize="xs" color="gray">
                            Answer
                          </FormLabel>
                          <Input
                            {...register(`answers.${i}.text` as const)}
                            placeholder="Type answer here"
                            value={answer.text}
                            size="xs"
                            onChange={(e) => editTextAnswer(i, e.target.value)}
                          />
                          <FormErrorMessage>{errors?.answers?.[i]?.text?.message}</FormErrorMessage>
                        </FormControl>
                      </HStack>
                    </ListItem>
                  ))}
                </List>
                <HStack p={3}>
                  <Button
                    onClick={addNewAnswer}
                    leftIcon={<AddIcon />}
                    colorScheme="primary"
                    variant="solid"
                    size="md"
                  >
                    NEW ANSWER
                  </Button>
                  <Spacer />
                </HStack>
              </VStack>
            )}
          </HStack>
        </Flex>
      </form>
    </Box>
  );
}

